import React, { useContext } from "react"
import { GlobalDataContext } from "../context/context"
import BaseLayout from "../components/global/BaseLayout"
import Paletacolor from "../components/global/Paletacolor";
import Modal from "../components/Home/Modal"
import HeroSection from "../components/Home/heroSection/HeroSection_2"
import BlockContentPrincipal from "../components/block/BlockContent_1"
import CallToActionBlockTopAbout from '../components/callToAction/CallToAction_1'
import BlockContentAbout from "../components/block/BlockContent_2"
import ValuesContent from '../components/values/Values_1'
import Directories from "../components/Home/Directories";
import VideoPromocional from "../components/global/VideoPromocional";
import ServicesHome from '../components/Services/ServicesHome_1'
import CallToAction2 from '../components/callToAction/CallToAction_2'
import Map from "../components/Contact/MapContent"
import Form from "../components/Contact/Form"
import ContentServices from "../components/Services/ContentServices"

// para agregar la frase de los años de experiencia o la licencia agregar sloganPrincipal={true}
// para agregar la lista de about agregar listsAbout={true}
// para agregar la lista de servicios agregar listsServices={true}

function Home() {

  const { rpdata } = useContext(GlobalDataContext);

  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">

        <Modal />
        <HeroSection
          title={rpdata?.dbSlogan?.[0]?.slogan}
          texts={rpdata?.dbValues?.[0].description.substring(0, 344)}
          urlVideo={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Electric.mp4?alt=media&token=1f5f7df4-e652-4708-b766-b7644ee1702c"}
          bgImg={rpdata?.gallery?.[14]}
          bgImg1={rpdata?.gallery?.[15]}
          bgImg2={rpdata?.gallery?.[16]}
          bgImg3={rpdata?.gallery?.[17]}
        />

        <BlockContentPrincipal
          sloganPrincipal
          listServices
          texts={rpdata?.dbHome?.[0].text}
          images={rpdata?.gallery?.[18]}
        />

        {/* call to action va junto con el blockContent_2 */}
        <CallToActionBlockTopAbout
          title={rpdata?.dbSlogan?.[3]?.slogan}
          texts={rpdata?.dbHome?.[1].text}
          images={rpdata?.gallery?.[4]}
        />
        <BlockContentAbout
          title={'a little about us'}
          texts={rpdata?.dbAbout?.[0]?.text}
          listAbout
          images={rpdata?.gallery?.[1]}
          bgImages={rpdata?.gallery?.[7]}
        />

        <ValuesContent
          mission={rpdata?.dbValues?.[0].description}
          vision={rpdata?.dbValues?.[1].description}
          whychoose={rpdata?.dbValues?.[2].description}
          img1={rpdata?.gallery?.[6]}
          img2={rpdata?.gallery?.[5]}
          img3={rpdata?.gallery?.[11]}
        />

        <Directories />

        {/* video promocional */}
        {
          rpdata?.videosPromo?.[0]?.activo?
            <VideoPromocional
              title={rpdata?.dbSlogan?.[2]?.slogan}
              text={rpdata?.dbAbout?.[1]?.text}
              linkVideo={`${rpdata?.videosPromo?.[0].link}`}
              image={`${rpdata?.videosPromo?.[0].img}`}
              vimeoVideo
            />
            : null
        }

        {/* validacion si es onepages */}
        {
          rpdata?.simpleWidgets?.[3]?.activo ?
            <ContentServices />
            :
            <ServicesHome />
        }

        <CallToAction2
          title={rpdata?.dbSlogan?.[4]?.slogan}
          subtitle={rpdata?.dbSlogan?.[0]?.slogan}
          phone={rpdata?.dbPrincipal?.phones?.[0]?.phone}
          bgImages={rpdata?.gallery?.[10]}
        />

        {/* our reviews */}
        {
          rpdata?.reviews?.isHomeOnly === true ?
            <div className="w-4/5 mx-auto py-[100px]">
              <h1 className="pb-10 text-center">{rpdata?.labels?.general?.titleReviews}</h1>
              <div className={`${rpdata?.reviews?.links?.[0]}`}></div>
            </div>
            : null
        }
        {rpdata?.brandingExtra?.[0]?.activo ? <Paletacolor /> : null}
        <Map
          // para cambiar el svgclas busque la clase en el archivo svgStyles
          svgClass={'svgMap2'}
        />

        <div className='w-full flex justify-center mb-20'>
          <div className='max-w-2xl p-5 mx-5 bg-footer shadow-lg text-white'
            data-aos="flip-down"
            data-aos-duration="2000"
          >
            <h2 className='text-center'>Send Us A Message</h2>
            <div className='p-4'>
              <Form />
            </div>
          </div>
        </div>

      </div>
    </BaseLayout>
  );
}

export default Home;
